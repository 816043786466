import { Facebook, Instagram, Pinterest, Twitter, Room, Phone, MailOutline } from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet, desktop } from "../responsive";

const Container = styled.div`
    display: flex;
    ${mobile({ textAlign: "center" })}
    ${tablet({ textAlign: "center" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const Logo = styled.h1`

`;

const Desc = styled.p`
    margin: 20px 0px;
`;

const SocialContainer = styled.div`
    display: flex;
    ${mobile({ alignItems: "center", justifyContent: "center" })}
    ${tablet({ alignItems: "center", justifyContent: "center" })}
`;

const SocialIcon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    background-color: #${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    ${mobile({ marginRight: "20px" })}
    ${tablet({ marginRight: "20px" })}
`;

const Center = styled.div`
    flex: 1;
    padding: 20px;
    ${mobile({ display: "none" })}
    ${tablet({display: "none"})}
    ${desktop({display: "none"})}
`;

const Title = styled.h3`
    margin-bottom: 30px;
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
`;

const ListItem = styled.li`
    width: 50%;
    margin-bottom: 10px;
`;


const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ backgroundColor: "#fff8f8", display: "none" })}
  ${tablet({ display: "none" })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Payment = styled.img`
    width: 50%;
    margin-top: 25px;
`;

const Copyright = styled.span`
    font-size: 12px;
    margin-top: 25px;
`;

const Footer = () => {
    return (
        <Container>
            <Left>
                <Logo>MODERN APPAREL</Logo>
                <Desc>
                    MODERN APPAREL is a one stop shop for all your fashion needs. We accept and ship orders across the globe. With high quality apparel you'll know your in the best hands with Modern Apparel.
                </Desc>
                <SocialContainer>
                    <SocialIcon color="3B5999">
                        <Facebook />
                    </SocialIcon>
                    <SocialIcon color="E4405F">
                        <Instagram />
                    </SocialIcon>
                    <SocialIcon color="55ACEE">
                        <Twitter />
                    </SocialIcon>
                    <SocialIcon color="E60023">
                        <Pinterest />
                    </SocialIcon>
                </SocialContainer>
                <Copyright>© {new Date().getFullYear()} MODERN APPAREL- ALL RIGHTS RESERVED</Copyright>
            </Left>
            <Center>
                <Title>Useful Links</Title>
                <List>
                    <ListItem>Home</ListItem>
                    <ListItem>Cart</ListItem>
                    <ListItem>Men's Fashion</ListItem>
                    <ListItem>Women's Fashion</ListItem>
                    <ListItem>Accessories</ListItem>
                    <ListItem>My Account</ListItem>
                    <ListItem>Order Tracking</ListItem>
                    <ListItem>Wishlist</ListItem>
                    <ListItem>Privacy Policy</ListItem>
                    <ListItem>Terms & Conditions</ListItem>
                </List>
            </Center>
            <Right>
                <Title>Contact</Title>
                <ContactItem>
                <Room style={{marginRight:"10px"}}/> 6233 Cantay Rd. Mississauga, ON L5R 3Y9
                </ContactItem>
                <ContactItem>
                <Phone style={{marginRight:"10px"}}/> +1 (647) 499-4219
                </ContactItem>
                <ContactItem>
                <MailOutline style={{marginRight:"10px"}} /> contact@modernapparel.ga
                </ContactItem>
                <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" />
            </Right>
        </Container>
    )
}

export default Footer
