import React from 'react'
import Announcement from '../components/Announcement'
import Categories from '../components/Categories'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Newsletter from '../components/Newsletter'
import Products from '../components/Products'
import Slider from '../components/Slider'
import styled from 'styled-components'
import { mobile } from '../responsive'

const HomeContainer = styled.div`
    ${mobile({ overflow: "hidden" })}
`;

const Home = () => {
    return (
        <HomeContainer>
            <Announcement/>
            <Navbar />
            <Slider />
            <Categories />
            <Products />
            <Newsletter />
            <Footer />
        </HomeContainer>
    )
}

export default Home
