// eslint-disable-next-line
import ProductList from "./pages/ProductList";
import Home from "./pages/Home";
// eslint-disable-next-line
import Product from "./pages/Product";
// eslint-disable-next-line
import Register from "./pages/Register";
// eslint-disable-next-line
import Login from "./pages/Login";
// eslint-disable-next-line
import Cart from "./pages/Cart";

const App = () => {
  return <Home/>
};

export default App;